/* Basic reset */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* NavBar container */
.navbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 2rem;
  color: #0000FF;
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it stays on top */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Add padding to the top of the body to account for the fixed Navbar */
body {
  padding-top: 4rem; /* Adjust this value based on the height of your Navbar */
}

/* Logo styles */
.navbar-logo {
  font-size: 1.75rem;
  font-weight: bold;
  font-family: 'Tahoma';
  color: #0000FF;
  margin-right: 2rem;
}

/* Navigation links container */
.navbar-links {
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding-left: 0;
}

/* Individual link styles */
.navbar-links a {
  color: #0000FF;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative; /* For positioning the label */
}

/* Add Free label styles */
.ai-chatbot-item {
  position: relative;
}

.free-label {
  position: absolute;
  top: -10px;
  left: 0;
  background-color: #ff0000; /* Red background for visibility */
  color: #ffffff; /* White text color */
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: bold;
}

/* Link hover and active states */
.navbar-links a:hover, .navbar-links a.active {
  color: #ffffff;
  background-color: #0000FF;
  border-radius: 5px;
}

/* Hamburger menu icon */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
}

.bar {
  height: 2px;
  width: 24px;
  background-color: #0000FF;
  margin: 4px 0;
  transition: 0.4s;
}

/* Rotate animation for the hamburger menu */
.rotate {
  transform: rotate(45deg);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-links {
    position: absolute;
    top: 60px; /* Adjust if necessary */
    right: 0;
    background-color: #ffffff;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    z-index: 1000;
  }

  .navbar-links.open {
    max-height: 300px; /* Adjust according to the number of links */
  }

  .navbar-toggle {
    display: flex;
  }
}
