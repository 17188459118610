/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Roboto:wght@400&display=swap');

/* Blue Grid Background */
/* .grid-background {
    background: linear-gradient(90deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                #270aa6;
    background-size: 40px 40px;
  } */

/* Body container */
.body-container {
  /* padding: 2rem; */
  font-family: 'Roboto', sans-serif; /* Unified font */
  background-color: #f9f9f9;
  color: #333;
}

/* Hero section - full screen */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75vh; /* Adjusted height */
  padding: 2rem;
  background-color: #ffffff;
  flex-wrap: wrap; /* Adjust layout on smaller screens */
  position: relative;
  z-index: 1;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                #ffffff; */
    background-size: 40px 40px;
    z-index: -1;
  }

.hero-content {
  width: 100%;
  max-width: 600px; /* Set a maximum width */
  margin-bottom: 2rem;
  text-align: left;
}

.hero-content h1 {
  color: #0000FF;
  font-size: 3.5rem;
  font-family: 'Lora', serif; /* Unified font */
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

.hero-video {
  width: 100%;
  max-width: 600px; /* Set a maximum width */
}

.video-placeholder {
  width: 100%;
  height: 300px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #999;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* About Us Section */
.about-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
    padding: 2rem;
    position: relative;
    z-index: 1;
    background-color: #ffffff;
  }
  
  .about-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                #ffffff; */
    background-size: 40px 40px;
    z-index: -1;
  }

.about-content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.about-text {
  text-align: justify; /* Justify text alignment */
}

.about-text h2 {
  color: #0000FF;
  font-family: 'Lora', serif; /* Unified font */
  font-size: 2rem;
  text-align: center; /* Center-align header */
  margin-bottom: 1rem;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.about-text strong {
  color: #0000FF;
}

/* Services Section */
.services-section {
    padding: 2rem;
    position: relative;
    z-index: 1;
    background-color: #ffffff;
  }
  
  .services-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                linear-gradient(180deg, rgba(0, 0, 255, 0.1) 1px, transparent 1px),
                #ffffff; */
    background-size: 40px 40px;
    z-index: -1;
  }

.services-section h2 {
  color: #0000FF;
  font-family: 'Lora', serif; /* Unified font */
  font-size: 2rem;
  text-align: center; /* Center-align header */
  margin-bottom: 1.5rem;
}

.services-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 2rem;
}

.service-item {
  background-color: #0000FF;
  color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  max-width: 300px; /* Adjusted width for horizontal layout */
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
}

.service-item h3 {
  font-family: 'Lora', serif; /* Unified font */
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

.service-item p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.service-item:hover {
  transform: translateY(-5px);
}

.copy-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  transition: color 0.3s ease;
}

.copy-button:hover {
  color: #e0e0e0;
}

/* Contact section */
#contact a {
  color: #0000FF;
  text-decoration: none;
  font-weight: bold;
}

#contact a:hover {
  text-decoration: underline;
}
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero-section, .about-section, .services-section {
      text-align: center;
    }
  
    .hero-content, .hero-video, .about-content, .services-list {
      max-width: 100%;
    }
  
    .hero-content h1 {
      font-size: 1.75rem;
    }
  
    .hero-content p, .service-item p {
      font-size: 1rem;
    }
  
    .video-placeholder {
      height: 200px;
    }
  
    .services-list {
      flex-direction: column;
      align-items: center;
    }
  
    .service-item {
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }
  
